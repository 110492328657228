import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import RichText from "../components/rich-text"
import styled from "styled-components"

const Text = styled.div`
  max-width: 672px;
  margin-bottom: 120px;
`

const PrivacyPolicy = ({
  location,
  data: {
    sanityPrivacyPolicy: { title, _rawRichtext },
  },
}) => {
  return (
    <Layout color="#F8EDE9" title={title} location={location}>
      <h1 className="reveal">{title}</h1>
      <Text className="reveal">
        {_rawRichtext && <RichText blocks={_rawRichtext} />}
      </Text>
    </Layout>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query PrivacyPolicyPageQuery {
    sanityPrivacyPolicy {
      title
      _rawRichtext
    }
  }
`
